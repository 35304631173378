import ApiBase from '@/api/base'

class Form {
	static create (self, form) {
		return self.http('post', `${ApiBase.baseUrl()}/positions-submitting/create`, form)
	}

	static getPagination (self, offset, limit) {
		self.http('get', `${ApiBase.baseUrl()}/positions-submitting/list/offset/${offset}/limit/${limit}`)
			.then(response => {
				ApiBase.setToStore(null, 'setPositionsSubmittingList', response.data)
			})
	}

	static getById (self, id) {
		return self.http('get', `${ApiBase.baseUrl()}/positions-submitting/${id}`)
	}
}

export default Form
