import api from '@/api/positions-submitting'
import positionApi from '@/api/positions'
import {notify} from "@/helpers/notifyHelper"
import Axios from 'axios'
import baseApi from "@/api/base";

export default {
	name: 'AdminShow',
	data () {
		return {
			form: null
		}
	},
	created() {
		if (this.id) {
			api.getById(this, this.id)
				.then(response => {
					if (response.data.length) {
						this.form = response.data[0]
						this.getPosition(this.form.positionId)
					}
				})
				.catch(err => {
					notify(self, 'error', err)
				})
		}
	},
	methods: {
		getPosition (id) {
			positionApi.getById(this, id)
				.then(resp => {
					this.position = resp.data[0]
				})
		},
		downloadResume(url, label) {
			Axios.get(url, { headers: baseApi.authHeaders(), responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data])
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = label
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
		}
	},
	computed: {
		id () {
			return this.$route.params.id
		}
	}
}
